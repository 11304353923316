<template>
  <div class="dsf_nc_assessment_new" :class="getCss">
    <el-form ref="formModel" :rules="rules" :model="formModel" label-width="80px" :disabled="isview">
      <!-- 人脸验证 -->
      <el-form-item v-if="showFace" class="dsf_form_item dsf_margin_bottom_30 dsf_form_item_renlian">
        <span slot="label" class="dsf_label">
          <div class="title">人脸验证</div>
          <el-switch class="label_switch" v-model="showFaceSwitchValue" @change="handlerSwitch('showFaceSwitchValue', 'showFaceSwitchValue', showFaceSwitchValue)"></el-switch>
        </span>
        <div class="conditions_content" v-if="showFaceSwitchValue">
          <el-form-item class="dsf_item_form" label="是否开启人脸验证：">
            <el-radio-group v-model="isFace" @change="hanlderRadio('face', 'faceValue', isFace)">
              <el-radio label="开启:1">
                开启
                <!-- <span class="dsf_radio_remark icon iconfont icon-093info">
                  <i class="remark_font">选择必考后，必须练习通过后才能获取学时得到证书</i>
                </span> -->
              </el-radio>
              <el-radio label="关闭:0">关闭</el-radio>
            </el-radio-group>
            <div class="renlian_desc">开启人脸验证后，该专题班将进行人脸验证，验证后通过后方可学习</div>

            <div class="set_renlian" v-if="formModel.face == '1'">
              <span>设置人脸验证事件：开始学习</span>
              <input type="number" v-model="formModel.verifyTime" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" onkeyup="clearNoNum(this)" />
              <span>分钟后，需要进行一次人脸验证</span>
            </div>
            <div class="set_renlian_desc" v-if="formModel.face == '1'">进入专题班必须进行人脸验证，设置后按照设置的学习时间进行一次验证，验证不通过则不可进行学习。</div>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 考核条件 -->
      <el-form-item v-if="conditions" class="dsf_form_item dsf_margin_bottom_30">
        <span slot="label" class="dsf_label">
          <div class="title">考核条件</div>
          <el-switch
            class="label_switch"
            v-if="showConditionsSwitch"
            v-model="periodEnableValue"
            @change="handlerSwitch('periodEnableValue', 'periodEnableText', periodEnableValue)"
          ></el-switch>
        </span>
        <div class="conditions_content" v-if="periodEnableValue">
          <el-form-item label="满足学时:" class="dsf_item_form" style="margin-top: 15px" prop="electivesPeriod">
            <span>&nbsp;&nbsp;&nbsp;&nbsp;选修&nbsp;</span>
            <el-input
              :disabled="isview"
              v-model="formModel.electivesPeriod"
              @change="eletivesChange(formModel.electivesPeriod, periodData.electivesPeriod)"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
              class="dsf_margin_bottom_10"
            ></el-input>
            <span>学时</span>
            <span>/{{ periodData.electivesPeriod || 0 }}学时&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;必修 {{ periodData.compulsoryPeriod || 0 }}/{{ periodData.compulsoryPeriod || 0 }}学时</span>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 随堂测试 -->
      <el-form-item v-if="quiz" class="dsf_form_item dsf_margin_bottom_30">
        <span slot="label" class="dsf_label">
          <div class="title">随堂测试</div>
          <el-switch class="label_switch" v-if="showQuizSwitch" v-model="quizEnableValue" @change="handlerSwitch('quizEnableValue', 'quizEnableText', quizEnableValue)"></el-switch>
        </span>
        <el-form-item class="quiz_content" v-show="quizEnableValue">
          <el-form-item class="dsf_margin_bottom_10" label="答题规则：">
            <el-radio-group v-model="quizCompleteValue" @change="hanlderRadio('quizCompleteValue', 'quizCompleteText', quizCompleteValue)">
              <el-radio label="必须做完才能继续课程:1">必须做完才能继续课程</el-radio>

              <el-radio label="可以跳过不做:0">可以跳过不做</el-radio>
            </el-radio-group>
            <div class="quiz_inner_box" v-if="formModel.quizCompleteValue">
              <el-form-item label="答题结果：">
                <el-radio-group v-model="quizRightValue" @change="hanlderRadio('quizRightValue', 'quizRightText', quizRightValue)">
                  <el-radio label="做题结果无论对错都可以继续学习:1">做题结果无论对错都可以继续学习</el-radio>
                  <el-radio label="做题结果必须全部正确方可继续学习:0">做题结果必须全部正确方可继续学习</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="答错设置：" v-show="quizRightValue == '做题结果必须全部正确方可继续学习:0'">
                <el-radio-group v-model="quizIgnoreErrorValue" @change="hanlderRadio('quizIgnoreErrorValue', 'quizIgnoreErrorText', quizIgnoreErrorValue)">
                  <el-radio label="答错继续答题:1">答错继续答题</el-radio>
                  <el-radio label="答错返回上个节点:0">答错返回上个节点</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item class="quiz2 no_label_name dsf_margin_bottom_10" label="答规则：">
            <el-radio-group v-model="quizAlwaysValue" @change="hanlderRadio('quizAlwaysValue', 'quizAlwaysText', quizAlwaysValue)">
              <el-radio label="每次都弹出题目:1">每次都弹出题目</el-radio>
              <el-radio label="只第一次上课弹出题目:0">只第一次上课弹出题目</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="ct_rules" label="出题规则：" prop="quizInterval">
            <div class="rules_inner dsf_line_box">
              <span>每</span>
              <el-input type="input" :disabled="isview" v-model="formModel.quizInterval" onkeyup="value=value.replace(/[^\d{1,}]/g,'')" class="dsf_margin_bottom_10"></el-input>
              <span>分钟做题一次，每次做</span>
              <el-input type="input" :disabled="isview" v-model="formModel.quizNum" onkeyup="value=value.replace(/[^\d{1,}]/g,'')"></el-input>
              <span>道题目。</span>
            </div>
          </el-form-item>
          <el-form-item class="dsf_remark no_label_name dsf_margin_bottom_10" label="出题规则：">
            <div class="font">注：视频时长：{{ duration }}</div>
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10" label="考试题目：" prop="quizlists">
            <DsfDataChoice
              class="no_label_name no_show_label data_choice_margin dsf_margin_bottom_10"
              :disabled="isview"
              v-model="quizlists"
              :autoComplete="quizAutoComplete"
              :metaDataType="quizMetaDataType"
              :chooseMetaData="quizChooseMetaData"
              :source="quizSource"
              :maxItemsNumber="quizMaxItemsNumber"
              :label="quizLabel"
              :showLabel="quizShowLabel"
              :textKey="quizTextKey"
              :valueKey="quizValueKey"
              :dialogUrl="quizDialogUrl"
              :dialogTextKey="quizDialogTextKey"
              :dialogValueKey="quizDialogValueKey"
              :dialogTitle="quizDialogSetting.split(',')[2]"
              :dialogWidth="quizDialogSetting.split(',')[0]"
              :dialogHeight="quizDialogSetting.split(',')[1]"
              @change="changeQuizDataChoice"
            />
          </el-form-item>
        </el-form-item>
      </el-form-item>
      <!-- 课后练习 -->
      <el-form-item v-if="exam" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">课后练习</div>
          <el-switch class="label_switch" v-if="showExamSwitch" v-model="enableExam" @change="handlerSwitch('enableExam', null, enableExam)"></el-switch>
        </span>
        <div class="conditions_content" v-show="enableExam">
          <el-form-item class="dsf_margin_bottom_10" label="练习类型：">
            <el-radio-group v-model="examNecessaryValue" @change="hanlderRadio('examNecessaryValue', 'examNecessaryText', examNecessaryValue)">
              <el-radio label="必填:1">
                必填
                <span class="dsf_radio_remark icon iconfont icon-093info">
                  <i class="remark_font">选择必考后，必须练习通过后才能获取学时得到证书</i>
                </span>
              </el-radio>
              <el-radio label="选填:0">选填</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10" label="练习名称：" prop="examlists">
            <DsfDataChoice
              :disabled="isview"
              class="no_label_name no_show_label data_choice_margin dsf_margin_bottom_10"
              v-model="examlists"
              :autoComplete="examAutoComplete"
              :metaDataType="examMetaDataType"
              :chooseMetaData="examChooseMetaData"
              :source="examSource"
              :maxItemsNumber="examMaxItemsNumber"
              :label="examLabel"
              :showLabel="examShowLabel"
              :textKey="examTextKey"
              :valueKey="examValueKey"
              :dialogUrl="examDialogUrl"
              :dialogTextKey="examDialogTextKey"
              :dialogValueKey="examDialogValueKey"
              :dialogTitle="examDialogSetting.split(',')[2]"
              :dialogWidth="examDialogSetting.split(',')[0]"
              :dialogHeight="examDialogSetting.split(',')[1]"
              @change="changeExamDataChoice"
            />
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10 reward_hurs" label="奖励学时:" prop="examHurs">
            <el-input
              :disabled="isview"
              v-model="formModel.examHurs"
              @change="handleChangevalue(formModel.examHurs, 'examHurs')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
              class="dsf_margin_bottom_10"
            />
            <span class="xs">学时</span>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 课后作业 -->
      <el-form-item v-if="homework" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">课后作业</div>
          <el-switch v-if="showHomeworkSwitch" v-model="homeworkEnabled" @change="handlerSwitch('homeworkEnabled', null, homeworkEnabled)"></el-switch>
        </span>
        <div class="conditions_content" v-show="homeworkEnabled">
          <el-form-item class="dsf_margin_bottom_10" label="作业类型：">
            <el-radio-group v-model="homeworkNecessaryValue" @change="hanlderRadio('homeworkNecessaryValue', 'homeworkNecessaryText', homeworkNecessaryValue)">
              <el-radio label="必填:1">
                必填
                <span class="dsf_radio_remark icon iconfont icon-093info">
                  <i class="remark_font">选择必填后，必须填写感悟后才能获取学时得到证书</i>
                </span>
              </el-radio>
              <el-radio label="选填:0">选填</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="作业描述：" class="dsf_margin_bottom_10" prop="homeworkRequirements">
            <el-input type="textarea" class="dsf_textarea" :rows="5" :resize="'none'" v-model="formModel.homeworkRequirements"></el-input>
          </el-form-item>
          <el-form-item label="成绩类型：" class="dsf_margin_bottom_10" v-if="homeworkTypeShow" prop="homeworkScoreTypeValue">
            <el-select class="dsf_select dsf_margin_bottom_10" v-model="formModel.homeworkScoreTypeValue" placeholder="下拉框，等级制、分数制" @change="handlerChangeSelect">
              <el-option v-for="item in scoreType" :key="item.value" :label="item.text" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10 reward_hurs" label="奖励学时:" prop="homeworkPeriod">
            <el-input
              :disabled="isview"
              v-model="formModel.homeworkPeriod"
              @change="handleChangevalue(formModel.homeworkPeriod, 'homeworkPeriod')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
              class="dsf_margin_bottom_10"
            />
            <span class="xs">学时</span>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 调查问卷 -->
      <el-form-item v-if="evaluate" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">调查问卷</div>
          <el-switch class="label_switch" v-if="showEvaluateSwitch" v-model="enableEvaluate" @change="handlerSwitch('enableEvaluate', null, enableEvaluate)"></el-switch>
        </span>
        <div class="conditions_content" v-show="enableEvaluate">
          <el-form-item class="dsf_margin_bottom_10" label="问卷类型：">
            <el-radio-group v-model="evaluateNecessaryValue" @change="hanlderRadio('evaluateNecessaryValue', 'evaluateNecessaryText', evaluateNecessaryValue)">
              <el-radio label="必填:1">
                必填
                <span class="dsf_radio_remark icon iconfont icon-093info">
                  <i class="remark_font">选择必答后，必须填写问卷后才能获取学时得到证书</i>
                </span>
              </el-radio>
              <el-radio label="选填:0">选填</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10" label="问卷模板：" prop="evaluatelists">
            <DsfDataChoice
              :disabled="isview"
              class="no_label_name no_show_label data_choice_margin"
              v-model="evaluatelists"
              :autoComplete="evaluateAutoComplete"
              :metaDataType="evaluateMetaDataType"
              :chooseMetaData="evaluateChooseMetaData"
              :source="evaluateSource"
              :maxItemsNumber="evaluateMaxItemsNumber"
              :label="evaluateLabel"
              :showLabel="evaluateShowLabel"
              :textKey="evaluateTextKey"
              :valueKey="evaluateValueKey"
              :dialogUrl="evaluateDialogUrl"
              :dialogTextKey="evaluateDialogTextKey"
              :dialogValueKey="evaluateDialogValueKey"
              :dialogTitle="evaluateDialogSetting.split(',')[2]"
              :dialogWidth="evaluateDialogSetting.split(',')[0]"
              :dialogHeight="evaluateDialogSetting.split(',')[1]"
              @change="changeEvaluateDataChoice"
            />
          </el-form-item>
          <el-form-item class="dsf_margin_bottom_10 reward_hurs" label="奖励学时:" prop="evaluatePeriod">
            <el-input
              v-model="formModel.evaluatePeriod"
              @change="handleChangevalue(formModel.evaluatePeriod, 'evaluatePeriod')"
              onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
              class="dsf_margin_bottom_10"
            />
            <span class="xs">学时</span>
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 证书 -->
      <el-form-item v-if="certificate" class="dsf_form_item">
        <span slot="label" class="dsf_label">
          <div class="title">证书</div>
          <el-switch
            class="label_switch"
            v-if="showCertificateSwitch"
            v-model="enableCertificate"
            @change="handlerSwitch('enableCertificate', null, enableCertificate)"
          ></el-switch>
        </span>
        <div class="conditions_content" v-show="enableCertificate">
          <el-form-item class="dsf_margin_bottom_10" label="证书名称：" prop="certificateLists">
            <DsfDataChoice
              :disabled="isview"
              class="no_label_name no_show_label data_choice_margin dsf_margin_bottom_10"
              v-model="certificateLists"
              :autoComplete="certificateAutoComplete"
              :metaDataType="certificateMetaDataType"
              :chooseMetaData="certificateChooseMetaData"
              :source="certificateSource"
              :maxItemsNumber="certificateMaxItemsNumber"
              :label="certificateLabel"
              :showLabel="certificateShowLabel"
              :textKey="certificateTextKey"
              :valueKey="certificateValueKey"
              :dialogUrl="certificateDialogUrl"
              :dialogTextKey="certificateDialogTextKey"
              :dialogValueKey="certificateDialogValueKey"
              :dialogTitle="certificateDialogSetting.split(',')[2]"
              :dialogWidth="certificateDialogSetting.split(',')[0]"
              :dialogHeight="certificateDialogSetting.split(',')[1]"
              @change="changeCertificateDataChoice"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item class="dsf_btns">
        <DsfButtonBar :disabled="isview" :buttons="buttons" :max-number="maxNumber" @save="onSubmit('formModel')" @close="onClose" />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default dsf.component({
  name: "DsfNcAssessmentNew",
  ctrlCaption: "考核设置",
  mixins: [$mixins.control, $mixins.buttonBar],
  props: {
    showColorLump: {
      type: Boolean,
      default: true,
    },

    showWeight: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "sgfresrgsrgfvesr",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "上一步",
            title: "",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "下一步",
            title: "",
          },
          {
            actionName: "close",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-guanbi2",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "关闭",
            title: "",
          },
        ];
      },
    },
    showFaceSwitchValue: {
      type: Boolean,
      default: true,
    },
    maxNumber: {
      type: Number,
      default: 3,
    },

    typeValue: {
      tyep: String,
      default: "class",
      desc: "类型设置",
    },

    //人脸识别
    showFace: {
      type: Boolean,
      default: true,
    },
    showFaceSwitch: {
      type: Boolean,
      default: true,
    },
    socketUrl: {
      type: String,
      default: "",
    },
    //考核条件
    conditions: {
      type: Boolean,
      default: true,
    },
    showConditionsSwitch: {
      type: Boolean,
      default: true,
    },

    // 随堂测试
    quiz: {
      type: Boolean,
      default: true,
    },
    quizLabel: {
      type: String,
      default: "考试名称",
    },

    quizShowLabel: {
      type: Boolean,
      default: true,
    },

    showQuizSwitch: {
      // 是否有打开关闭按钮
      type: Boolean,
      default: true,
    },

    quizDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", // #/pc/nc/teacher/selectTeacher
    },

    quizDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },

    quizDialogTextKey: {
      type: String,
      default: "",
    },

    quizDialogValueKey: {
      type: String,
      default: "",
    },

    quizAutoComplete: {
      type: Boolean,
      default: false,
    },

    quizMetaDataType: {
      type: String,
      default: "0",
    },

    quizChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },

    quizSource: {
      type: String,
      default: "", // /nc/teacher/search/name?
    },

    quizMaxItemsNumber: {
      type: Number,
      default: 20,
    },

    quizTextKey: {
      type: String,
      default: "text",
    },

    quizValueKey: {
      type: String,
      default: "value",
    },

    // 考试 课后练习
    exam: {
      type: Boolean,
      default: true,
    },
    showExamSwitch: {
      // 是否有打开关闭按钮
      type: Boolean,
      default: true,
    },

    examLabel: {
      type: String,
      default: "考试名称",
    },

    examShowLabel: {
      type: Boolean,
      default: true,
    },

    examTitleLabel: {
      type: String,
      default: "",
    },

    examDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", // #/pc/nc/teacher/selectTeacher
    },

    examDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },

    examDialogTextKey: {
      type: String,
      default: "",
    },

    examDialogValueKey: {
      type: String,
      default: "",
    },

    examAutoComplete: {
      type: Boolean,
      default: false,
    },

    examMetaDataType: {
      type: String,
      default: "0",
    },

    examChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },

    examSource: {
      type: String,
      default: "", // /nc/teacher/search/name?
    },

    examMaxItemsNumber: {
      type: Number,
      default: 20,
    },

    examTextKey: {
      type: String,
      default: "text",
    },

    examValueKey: {
      type: String,
      default: "value",
    },

    // 问卷 调查问卷
    evaluate: {
      type: Boolean,
      default: true,
    },
    showEvaluateSwitch: {
      // 是否有打开关闭按钮
      type: Boolean,
      default: true,
    },

    evaluateLabel: {
      type: String,
      default: "考试名称",
    },

    evaluateShowLabel: {
      type: Boolean,
      default: true,
    },

    evaluateTitleLabel: {
      type: String,
      default: "",
    },

    evaluateDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", // #/pc/nc/teacher/selectTeacher
    },

    evaluateDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },

    evaluateDialogTextKey: {
      type: String,
      default: "",
    },

    evaluateDialogValueKey: {
      type: String,
      default: "",
    },

    evaluateAutoComplete: {
      type: Boolean,
      default: false,
    },

    evaluateMetaDataType: {
      type: String,
      default: "0",
    },

    evaluateChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },

    evaluateSource: {
      type: String,
      default: "", // /nc/teacher/search/name?
    },

    evaluateMaxItemsNumber: {
      type: Number,
      default: 20,
    },

    evaluateTextKey: {
      type: String,
      default: "text",
    },

    evaluateValueKey: {
      type: String,
      default: "value",
    },

    // 作业
    homework: {
      type: Boolean,
      default: true,
    },
    showHomeworkSwitch: {
      type: Boolean,
      default: true,
    },
    homeworkTypeShow: {
      type: Boolean,
      default: true,
    },

    // 证书
    certificate: {
      type: Boolean,
      default: true,
    },

    showCertificateSwitch: {
      type: Boolean,
      default: true,
    },

    certificateDialogUrl: {
      type: String,
      default: "#/pc/nc/exam/exampaperlist", //#/pc/nc/teacher/selectTeacher
    },

    certificateDialogSetting: {
      type: String,
      default: "800px,500px,请选择",
    },

    certificateDialogTextKey: {
      type: String,
      default: "",
    },

    certificateDialogValueKey: {
      type: String,
      default: "",
    },

    certificateAutoComplete: {
      type: Boolean,
      default: false,
    },

    certificateMetaDataType: {
      type: String,
      default: "0",
    },

    certificateChooseMetaData: {
      type: Object,
      default() {
        return {};
      },
    },

    certificateSource: {
      type: String,
      default: "", //nc/teacher/search/name?/
    },

    certificateMaxItemsNumber: {
      type: Number,
      default: 20,
    },

    certificateTextKey: {
      type: String,
      default: "text",
    },
  },
  created() {
    localStorage.setItem("socketUrl", this.socketUrl);
  },
  data() {
    return {
      duration: this.$route.query.duration || "0",
      quizlists: [], // 随堂测试考试
      examlists: [], // 课后练习
      evaluatelists: [], // 问卷 \ 评论
      certificateLists: [], //证书
      id: this.$route.query.id || "20114748e99840d3a86575f357b0053c",
      quizEnableValue: true,
      quizCompleteValue: "可以跳过不做:0",
      quizRightValue: "做题结果必须全部正确方可继续学习:0",
      quizIgnoreErrorValue: "答错返回上个节点:0",
      quizAlwaysValue: "只第一次上课弹出题目:0",
      enableExam: true,
      examNecessaryValue: "选填:0",
      isFace: "关闭:0",
      homeworkEnabled: true,
      homeworkNecessaryValue: "选填:0",
      enableEvaluate: true, // 开启问卷\评估
      evaluateNecessaryValue: "选填:0", //问卷\评估是否必须  1:是， 0:否
      enableCertificate: true, //开启证书
      periodData: {}, //考核
      periodEnableValue: true, //
      formModel: {
        periodEnableText: "",
        periodEnableValue: 0,
        face: "1", //是否开启人脸验证
        verifyTime: "", //人脸验证时长
        electivesPeriod: "", //选修学时
        compulsoryPeriod: "", //必修学时
        businessId: "", // 班级/课程id
        quizEnableValue: 0, //是否开启随堂测试 1:是， 0:否
        quizEnableText: "",
        quizCompleteValue: 0, // 随堂测试要求完成(是否必须做)  1:是， 0:否
        quizCompleteText: "",
        quizIgnoreErrorValue: 0, // 错题是否继续（做错能否继续学习）  1:是， 0:否
        quizIgnoreErrorText: "",
        quizRightValue: 0, // 做题必须正确(正确才能做下一题)  1:是， 0:否
        quizRightText: "",
        quizAlwaysValue: 0, //是否每次弹题  1:是， 0:否
        quizAlwaysText: "",
        quizInterval: "", //做题时间间隔(分钟)
        quizNum: "", //每次题目数量
        quizValue: "", // 随堂测试考试
        quizText: "",
        enableExam: 0, //开启考试_文本 ；课后练习
        examNecessaryValue: 0, //是否必考   1:是， 0:否
        examNecessaryText: "",
        examValue: "", //试卷_值
        examText: "", // 试卷_文本
        examHurs: "", //考试奖励学时_文本
        homeworkEnabled: 0, //是否启用作业 0否，1是

        homeworkNecessaryValue: 0, //作业是否必须 1:是， 0:否
        homeworkNecessaryText: "",
        homeworkId: "", // 作业id
        homeworkRequirements: "", //作业标题\作业描述
        homeworkScoreTypeText: "", //作业打分类文本
        homeworkScoreTypeValue: "", //作业打分类型值,
        homeworkPeriod: "", // 作业学时字段
        enableEvaluate: 0, // 开启评估_文本
        evaluateValue: "", //问卷 \ 评估_值
        evaluateText: "", //问卷 \ 评估_文本
        evaluateNecessaryValue: "", //问卷\评估是否必须  1:是， 0:否
        evaluateNecessaryText: "",
        evaluatePeriod: "", // 评估学时
        enableCertificate: "", // 开启证书_文本
        rtypeValue: "", //类型   班级：course, 班级：class; 直播：live
        rtypeText: "",
      },

      // 作业
      scoreType: [
        {
          text: "等级制",
          value: "1",
        },

        {
          text: "分数制",
          value: "2",
        },
      ],
      isview: 0,
      rules: {
        electivesPeriod: [{ validator: this.validateElectivePeriod, trigger: "change", required: true }],
        quizInterval: [{ validator: this.validateQuizInterval, trigger: "change", required: true }],
        quizlists: [{ validator: this.validateQuizlists, trigger: "change", required: true }],
        examlists: [{ validator: this.validateExamlists, trigger: "change", required: true }],
        examHurs: [{ validator: this.validateExamHurs, trigger: "change", required: true }],
        homeworkRequirements: [{ validator: this.validateHomeworkRequirements, trigger: "change", required: true }],
        homeworkScoreTypeValue: [{ validator: this.validateHomeworkScoreTypeValue, trigger: "change", required: true }],
        homeworkPeriod: [{ validator: this.validateHomeworkPeriod, trigger: "change", required: true }],
        evaluatelists: [{ validator: this.validateEvaluatelists, trigger: "change", required: true }],
        evaluatePeriod: [{ validator: this.validateEvaluatePeriod, trigger: "change", required: true }],
        certificateLists: [{ validator: this.validateCertificateLists, trigger: "change", required: true }],
      },
    };
  },

  watch: {
    quizRightValue(val) {
      if (val == "做题结果无论对错都可以继续学习:1") {
        this.quizIgnoreErrorValue = "答错返回上个节点:0";
        this.hanlderRadio("quizIgnoreErrorValue", "quizIgnoreErrorText", this.quizIgnoreErrorValue);
      }
    },
    formModel: {
      handler(n, o) {
        console.log(n);
      },
      deep: true,
    },
    showFaceSwitchValue: {
      handler(n, o) {
        console.log(n);
        localStorage.setItem("faceSwitchValue", JSON.stringify(n));
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(["getHomeWork"]),
    getCss() {
      const $class = this.$getClass();
      $class.push(this.showColorLump ? "ds-showColorLump" : "", this.showWeight ? "ds_bold" : "");
      return $class;
    },
  },

  mounted() {
    if (!this.isDesign) {
      this.isview = this.$route.query.isview || this.queryString["isview"] || 0;
      this.duration = this.$route.query.duration || this.queryString["duration"] || "0";
      this.isview = this.isview == 1 ? true : false;
      // this.assessmentInfo(this.id);
      this.initData(); // 查数据
      this.initPeriod(); // 查学时
      if (this.isview) {
        // this.buttons.forEach(items => {
        // if (items.actionName != "save") {
        //   items.disabled = true;
        // }
        // });
      }
    }
  },

  methods: {
    reloadData() {
      if (!this.isDesign) {
        this.initPeriod(); // 查学时
      }
    },
    ...mapActions(["assessmentInfo"]),
    // 获取默认数据
    initData() {
      let loading = dsf.layer.pc.loading();
      let id = this.id;
      dsf.http
        .get("/nc/class/assessment/info", { id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.formModel = Object.assign({}, data);
            this.formModel.businessId = this.formModel.businessId || this.id;
            if (!this.formModel.rtypeValue) {
              switch (this.typeValue) {
                case "class":
                  this.formModel.rtypeValue = "class";
                  this.formModel.rtypeText = "班级";
                  break;
                case "course":
                  this.formModel.rtypeValue = "course";
                  this.formModel.rtypeText = "课程";
                  break;
                case "live":
                  this.formModel.rtypeValue = "live";
                  this.formModel.rtypeText = "直播";
                  break;
                default:
                  this.formModel.rtypeValue = "class";
                  this.formModel.rtypeText = "班级";
              }
            }

            for (const key in this.formModel) {
              if (Object.hasOwnProperty.call(this.formModel, key)) {
                if (
                  key == "quizEnableValue" ||
                  key == "enableExam" ||
                  key == "homeworkEnabled" ||
                  key == "enableEvaluate" ||
                  key == "enableCertificate" ||
                  key == "periodEnableValue" ||
                  key == "face"
                ) {
                  //随堂测试、课后练习、课后作业、调查问卷、证书、考核,人脸识别
                  this[key] = this.formModel[key] && Number(this.formModel[key]) ? true : false;
                  this.formModel[key] = this.formModel[key] && Number(this.formModel[key]) ? 1 : 0;
                }
                // 人脸识别
                if (key == "face") {
                  this.showFaceSwitchValue = this.formModel[key] && Number(this.formModel[key]) ? true : false;
                  this.isFace = this.formModel[key] ? "开启:1" : "关闭:0";
                  this[key] = this.formModel[key] ? "开启:1" : "关闭:0";
                  this.hanlderRadio("face", "", this[key]);
                  console.log("this.formModel", this.formModel);
                }
                //考核
                if (key == "periodEnableValue") {
                  // 是否开启考核文本
                  this.formModel.periodEnableText = this.formModel[key] ? "是" : "否";
                }

                // 随堂测试
                if (key == "quizEnableValue") {
                  // 是否开启随堂测试文本
                  this.formModel.quizEnableText = this.formModel[key] ? "是" : "否";
                }
                if (key == "quizCompleteValue") {
                  // 随堂测试要求完成(是否必须做)  1:是， 0:否
                  this[key] = this.formModel[key] ? "必须做完才能继续课程:1" : "可以跳过不做:0";
                  this.hanlderRadio("quizCompleteValue", "quizCompleteText", this[key]);
                }
                if (key == "quizIgnoreErrorValue") {
                  // 错题是否继续（做错能否继续学习）  1:是， 0:否
                  this[key] = this.formModel[key] ? "答错继续答题:1" : "答错返回上个节点:0";
                  this.hanlderRadio("quizIgnoreErrorValue", "quizIgnoreErrorText", this[key]);
                }
                if (key == "quizRightValue") {
                  //答题结果：
                  this[key] = this.formModel[key] ? "做题结果无论对错都可以继续学习:1" : "做题结果必须全部正确方可继续学习:0";
                  this.hanlderRadio("quizRightValue", "quizRightText", this[key]);
                }
                if (key == "quizAlwaysValue") {
                  // 每次都弹出题目
                  this[key] = this.formModel[key] ? "每次都弹出题目:1" : "只第一次上课弹出题目:0";
                  this.hanlderRadio("quizAlwaysValue", "quizAlwaysText", this[key]);
                }

                //课后练习
                if (key == "examNecessaryValue") {
                  this[key] = this.formModel[key] ? "必填:1" : "选填:0";
                  this.hanlderRadio("examNecessaryValue", "examNecessaryText", this[key]);
                }

                // 课后作业
                if (key == "homeworkNecessaryValue") {
                  this[key] = this.formModel[key] ? "必填:1" : "选填:0";
                  this.hanlderRadio("homeworkNecessaryValue", "homeworkNecessaryText", this[key]);
                }

                // 调查问卷
                if (key == "evaluateNecessaryValue") {
                  this[key] = this.formModel[key] ? "必填:1" : "选填:0";
                  this.hanlderRadio("evaluateNecessaryValue", "evaluateNecessaryText", this[key]);
                }
              }
            }

            if (this.formModel.rtypeValue == "class") {
              this.periodEnableValue = true;
              this.formModel.periodEnableValue = 1;
            }

            /*
              随堂测试 考试名称
              quizValue: "", //试卷_值
              quizText: "", //试卷_文本
            */
            if (this.formModel.quizValue) {
              var quizObj = {
                value: this.formModel.quizValue,
                text: this.formModel.quizText,
              };
              this.quizlists.push(quizObj);
            }

            /*
              课后练习
              examValue
              examText
            */
            if (this.formModel.examValue) {
              var examObj = {
                value: this.formModel.examValue,
                text: this.formModel.examText,
              };
              this.examlists.push(examObj);
            }

            /*
              评估模板
              evaluateValue: "", // (评估_值)
              evaluateText: "", // (评估_文本)
            */
            if (this.formModel.evaluateValue) {
              var evaluateObj = {
                value: this.formModel.evaluateValue,
                text: this.formModel.evaluateText,
              };
              this.evaluatelists.push(evaluateObj);
            }

            /*
              证书名称
              certificateValue: "", // (证书_值)
              certificateText: "", // (证书_文本)
            */
            if (this.formModel.certificateValue) {
              var certificateObj = {
                value: this.formModel.certificateValue,
                text: this.formModel.certificateText,
              };
              this.certificateLists.push(certificateObj);
            }
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    // 禁止输入小数点
    clearNoNum(obj) {
      obj.value = obj.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      obj.value = obj.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      obj.value = obj.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (obj.value.indexOf(".") < 0 && obj.value != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        obj.value = parseFloat(obj.value);
      }
    },

    // 查学时
    initPeriod() {
      let loading = dsf.layer.pc.loading();
      let id = this.id;
      dsf.http
        .get("/nc/pack/unit/period", { id }, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            this.periodData = data;
            this.formModel.compulsoryPeriod = data.compulsoryPeriod ? Number(data.compulsoryPeriod) : 0;
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },

    // 考核条件
    eletivesChange(val, item) {
      if (/^\d\.\d+?$/.test(val)) {
        val = Number(val).toFixed(2);
      }
      // console.log(val, "考核条件==》");
      if (Number(val) < Number(item) || Number(val) == Number(item)) {
        this.formModel.electivesPeriod = val;
      } else {
        this.formModel.electivesPeriod = item || 0;
      }

      if (val.includes(".")) {
        return (this.formModel.electivesPeriod = Number(val).toFixed(2));
      }
    },

    //作业成绩类型
    handlerChangeSelect(value) {
      let val = this.scoreType.filter(item => item.value == value);
      this.formModel.homeworkScoreTypeValue = val ? val[0].value : "";
      this.formModel.homeworkScoreTypeText = val ? val[0].text : "";
      // console.log(888, val, this.formModel);
    },

    // 切换值得转换
    handlerSwitch(key, key2, val) {
      this.formModel[key] = val ? 1 : 0;
      if (dsf.isEmptyObject(key2)) return;
      this.formModel[key2] = val ? "是" : "否";
    },
    // 单选赋值
    hanlderRadio(key, key2, val) {
      if (!val) return;
      let value = val.split(":")[1];
      let text = val.split(":")[0];
      this.formModel[key] = Number(value);
      this.formModel[key2] = text;
      if (key == "face") {
        this.formModel[key] == 0 ? (this.showFaceSwitchValue = false) : (this.showFaceSwitchValue = true);
      }
      this.$forceUpdate();
    },

    // 数据改变 强制保留小数点后两位
    handleChangevalue(value, key) {
      this.formModel[key] = Number(value).toFixed(2);
    },

    changeQuizDataChoice(item) {
      // dsfDataChoice组件 随堂测试值改变时候
      this.formModel.quizValue = "";
      this.formModel.quizText = "";
      this.change(item.args, "quiz");
    },
    changeEvaluateDataChoice(item) {
      // dsfDataChoice组件 问卷值改变时候
      this.formModel.evaluateValue = "";
      this.formModel.evaluateText = "";
      this.change(item.args, "evaluate");
    },

    changeExamDataChoice(item) {
      // dsfDataChoice组件 考试值改变时候
      this.formModel.examValue = "";
      this.formModel.examText = "";
      this.change(item.args, "exam");
    },

    changeCertificateDataChoice(item) {
      // dsfDataChoice组件 证书值改变时候
      this.formModel.certificateValue = "";
      this.formModel.certificateText = "";
      this.change(item.args, "certificate");
    },

    change(array, type) {
      array.forEach(element => {
        switch (type) {
          case "exam":
            this.formModel.examValue = element.value;
            this.formModel.examText = element.text;
            break;
          case "evaluate":
            this.formModel.evaluateValue = element.value;
            this.formModel.evaluateText = element.text;
            break;
          case "certificate":
            this.formModel.certificateValue = element.value;
            this.formModel.certificateText = element.text;
            break;
          case "quiz":
            this.formModel.quizValue = element.value;
            this.formModel.quizText = element.text;
            break;
          case "face":
            break;
          default:
            break;
        }
      });
    },

    onSubmit(formName) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          for (const key in this.formModel) {
            if (Object.hasOwnProperty.call(this.formModel, key)) {
              if (key.includes("period") && !this.periodEnableValue) {
                this.formModel.electivesPeriod = "";
              }
              if (!this.formModel.face) {
                this.formModel.verifyTime = null;
              }
              if (key.includes("quiz") && !this.quizEnableValue) {
                this.formModel[key] = "";
                this.quizlists = [];
                this.quizCompleteValue = "可以跳过不做:0";
                this.quizRightValue = "做题结果必须全部正确方可继续学习:0";
                this.quizIgnoreErrorValue = "答错返回上个节点:0";
                this.quizAlwaysValue = "只第一次上课弹出题目:0";
              }
              if (key.includes("exam") && !this.enableExam) {
                this.formModel[key] = "";
                this.examlists = [];
              }
              if (key.includes("evaluate") && !this.enableEvaluate) {
                this.formModel[key] = "";
                this.evaluatelists = [];
              }
              if (key.includes("certificate") && !this.enableCertificate) {
                this.formModel[key] = "";
                this.certificateLists = [];
              }
            }
          }
          if (this.showFaceSwitchValue && this.formModel.face && (!this.formModel.verifyTime || this.formModel.verifyTime == "0")) {
            dsf.layer.pc.message("请设置人脸识别时间", false);
            return;
          }
          this.assessmentSave();
        } else {
          console.log(Object.getOwnPropertyNames(obj), obj, "error submit!!");
          Object.getOwnPropertyNames(obj).forEach((keys, index) => {
            console.log(4444, keys, index);
            if (keys && index == 0) {
              console.log(obj[keys], "error submit!!");
              dsf.layer.pc.message(obj[keys][0]["message"], false);
            }
          });

          return false;
        }
      });
    },

    assessmentSave() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .post("/nc/class/assessment/save", this.formModel, $$webRoot.nc)
        .done(res => {
          if (!res.success) {
            this.error(res);
          } else {
            let { data } = res;
            dsf.layer.pc.message("保存成功", true);
            this.$emit("submit_succ", data);
          }
        })
        .error(err => {
          this.error(err);
        })
        .always(res => {
          dsf.layer.pc.closeLoading(loading);
        });
    },

    onClose() {
      window.close();
    },

    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
    /*自定义校验*/
    validateElectivePeriod(rule, value, callback) {
      //选修
      if (this.periodEnableValue && value == "") {
        callback(new Error("请输入选修学时"));
      } else {
        callback();
      }
    },
    validateQuizInterval(rule, value, callback) {
      //出题规则
      console.log("出题规则==>", this.formModel.quizNum, this.formModel.quizInterval);
      if (this.quizEnableValue && (this.formModel.quizNum === "" || this.formModel.quizNum === null || this.formModel.quizInterval === "" || this.formModel.quizInterval == null)) {
        callback(new Error("请填写出题规则"));
      } else {
        callback();
      }
    },
    validateQuizlists(rule, value, callback) {
      console.log(this.quizEnableValue, "出题规则===>", this.quizlists);
      //出题规则
      if (this.quizEnableValue && (!this.quizlists || this.quizlists.length == 0)) {
        callback(new Error("请选择考试题目"));
      } else {
        callback();
      }
    },
    validateExamlists(rule, value, callback) {
      console.log("练习名称===>", this.examlists);
      //练习名称
      if (this.enableExam && (!this.examlists || this.examlists.length == 0)) {
        callback(new Error("请选择练习名称"));
      } else {
        callback();
      }
    },
    validateExamHurs(rule, value, callback) {
      console.log("奖励学时===>", this.examHurs);
      //奖励学时
      if (this.enableExam && (value === "" || value == null)) {
        callback(new Error("请填写奖励学时"));
      } else {
        callback();
      }
    },
    validateHomeworkRequirements(rule, value, callback) {
      console.log("作业描述===>", value, this.formModel.homeworkRequirements);
      //作业描述
      if (this.homeworkEnabled && (value === "" || value == null)) {
        callback(new Error("请填写作业描述"));
      } else {
        callback();
      }
    },
    validateHomeworkScoreTypeValue(rule, value, callback) {
      console.log("成绩类型===>", value, this.formModel.homeworkScoreTypeValue);
      //成绩类型
      if (this.homeworkEnabled && (value === "" || value == null)) {
        callback(new Error("请选择成绩类型"));
      } else {
        callback();
      }
    },
    validateHomeworkPeriod(rule, value, callback) {
      console.log("奖励学时===>", value, this.formModel.homeworkPeriod);
      //奖励学时
      if (this.homeworkEnabled && (value === "" || value == null)) {
        callback(new Error("请填写奖励学时"));
      } else {
        callback();
      }
    },
    validateEvaluatelists(rule, value, callback) {
      console.log("问卷模板===>", value, this.evaluatelists);
      //问卷模板
      if (this.enableEvaluate && (!this.evaluatelists || this.evaluatelists.length == 0)) {
        callback(new Error("请选择问卷模板"));
      } else {
        callback();
      }
    },
    validateEvaluatePeriod(rule, value, callback) {
      console.log("调查问卷-奖励学时===>", value, this.evaluatelists);
      //调查问卷-奖励学时
      if (this.enableEvaluate && (value === "" || value == null)) {
        callback(new Error("请填写奖励学时"));
      } else {
        callback();
      }
    },
    validateCertificateLists(rule, value, callback) {
      console.log("证书-证书名称===>", value, this.certificateLists);
      //证书-证书名称
      if (this.enableCertificate && (!this.certificateLists || this.certificateLists.length == 0)) {
        callback(new Error("请选择证书名称"));
      } else {
        callback();
      }
    },
  },
});
</script>
