<template>
  <div class="dsf_nc_zxqy_banner" v-if="isDesign">
    <ul class="left_course_wrap">
      <li class="course_items active">
        <div class="course_item_lists">
          <span class="font">数字经济/形式分析</span>
          <span class="icon iconfont icon-gengduo2"></span>
        </div>
        <div class="course_item_desc">
          <div class="course_title_wrap">
            <div class="first_title">数字经济</div>
            <div class="second_title">形式分析</div>
          </div>
          <ul class="course_inner_wrap">
            <li class="course_inner_items">
              <div class="image">
                <dsf-nc-image :src="''" />
              </div>
              <div class="desc">
                <div class="item_title">创企风采展示</div>
                <div class="footer">
                  <div class="teacher">朱爽</div>
                  <div class="count">
                    <i class="icon iconfont icon-yanjing1"></i>
                    4527
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="banner_inner">
      <el-carousel height="440px" :autoplay="false">
        <el-carousel-item v-for="item in 4" :key="item">
          <dsf-nc-image :src="''" />
          <div class="footer">
            <div class="title ell">2021上海国际生物医药产业周开幕，加快打造具有全球影响力的生物医药上海高地！</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="dsf_nc_zxqy_banner" v-else>
    <ul class="left_course_wrap">
      <li v-for="(item, index) in courseData" :key="item._id" @mouseover="hoverCourses = index" @mouseleave="hoverCourses = null" :class="`course_items${hoverCourses === index ? ' active' : ''}`">
        <div class="course_item_lists">
          <span class="font">{{ item.first_name || "" }} / {{ item.second_name || "" }}</span>
          <span class="icon iconfont icon-gengduo2"></span>
        </div>
        <div class="course_item_desc" v-if="hoverCourses === index">
          <div class="course_title_wrap">
            <div class="first_title">{{ item.first_name || "" }}</div>
            <div class="second_title">{{ item.second_name || "" }}</div>
          </div>
          <ul class="course_inner_wrap" v-if="item.lists && item.lists.length > 0">
            <li class="course_inner_items" v-for="(it, idx) in item.lists" :key="it._id" @click="_clickBefore(clickCourseDetail, it)">
              <div class="image">
                <dsf-nc-image :src="it['nc_courses_page_listsource.cover'] | imgFormat" :error-src="defaultSrc"></dsf-nc-image>
              </div>
              <div class="desc">
                <div class="item_title">{{ it["nc_courses_page_listsource._name"] || "" }}</div>
                <div class="footer">
                  <div class="teacher">{{ it["nc_courses_page_listsource.teacher_text"] || "" }}</div>
                  <div class="count">
                    <i class="icon iconfont icon-yanjing1"></i>
                    {{ it["nc_courses_page_listsource.view_totle"] || "" }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="banner_inner">
      <el-carousel height="440px" :loop="loop" :interval="interval" :autoplay="autoplay" :arrow="'never'">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <div class="banner_box" @click="_clickBefore(clickBanner, item)">
            <dsf-nc-image :src="item.cover | imgFormat" :fit="'fill'" :error-src="defaultSrc" />
            <div class="footer">
              <div class="title ell">{{ item.title }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcZxqyBanner",
  ctrlCaption: "中小企业轮播",
  mixins: [$mixins.control],
  props: {
    setHeight: {
      type: String,
      default: "600px",
      desc: "轮播图高度",
    },
    interval: {
      type: Number,
      default: 3000000,
      desc: "自动切换的时间间隔，单位为毫秒",
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    sourceBannerUrl: {
      type: String,
      default: "item/nc/home/bannerList?code=001&pageSize=6&itemRoute=zxqy",
    },
    sourcesFlUrl: {
      type: String,
      default: "item/nc/home/itemFlml?itemRoute=zxqy",
    },
    itemCourseListUrl: {
      type: String,
      default: "/item/nc/home/itemCourseList?itemRoute=zxqy",
    },
    courseDetilUrl: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
      bannerList: [],
      courseData: [],
      hoverCourses: null,
    };
  },
  mounted() {
    if (!this.isDesign) {
      this.initBanner();
      this.initCategory();
    }
  },
  methods: {
    initBanner() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.sourceBannerUrl, {})
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.bannerList = data;
            if (!this.bannerList.length) {
              this.bannerList.push({ cover: this.defaultSrc, title: "暂无数据" });
            }
          }
        })
        .error(err => {
          this.error(err);
          console.log(err);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    initCategory() {
      let loading = dsf.layer.pc.loading();
      dsf.http
        .get(this.sourcesFlUrl, {})
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.courseData = data;
            if (this.courseData.length > 7) {
              this.courseData = this.courseData.slice(0, 7);
            }
            this.courseData.forEach((element, index) => {
              this.getCourse(element._id, index);
            });
          }
        })
        .error(err => {
          this.error(err);
          console.log(err);
        })
        .always(() => {
          dsf.layer.pc.closeLoading(loading);
        });
    },
    clickCourseDetail(item) {
      if (dsf.isEmptyObject(this.courseDetilUrl)) return;
      let { url, state } = this.courseDetilUrl;
      url = this.$replace(url, item);
      this.$open({ url, state });
    },
    clickBanner(item) {
      // if (!this.bannerMoreUrl) return;
      this.$open({ url: item.url, state: item.opentype_value });
    },
    // _clickBefore(next, ...params) {
    //   if (this.isDesign) return;
    //   let res = this.$checkLogin();
    //   if (res && res.then) {
    //     res.then(r => {
    //       if (r !== false) {
    //         next(...params);
    //       }
    //     });
    //   } else if (res !== false) {
    //     next(...params);
    //   }
    // },
    getCourse(id, index) {
      let params = {};
      let analyzingUrl = dsf.url.analyzing(this.itemCourseListUrl);
      let url = "";
      if (!dsf.isEmptyObject(analyzingUrl.query)) {
        url = analyzingUrl.path;
        params = Object.assign({}, analyzingUrl.query, { id });
      } else {
        url = this.itemCourseListUrl;
        params = { id };
      }

      dsf.http
        .get(url, params)
        .done(res => {
          let { data, success } = res;
          if (!success) {
            this.error(res);
          } else {
            this.courseData[index] = Object.assign({}, this.courseData[index], { lists: data });
          }
        })
        .error(err => {
          this.error(err);
          console.log(err);
        })
        .always(() => {});
    },

    error(err) {
      dsf.layer.pc.message(err.message, false);
    },
  },
});
</script>
