<template>
  <div class="dsf_nc_saas saas_setting_url">
    <div class="title t1 margint20 marginb20">URL地址设置</div>
    <div class="subtitle margint20 marginb20">简易设置</div>
    <div class="line url_input">
      <template v-if="urlaction">
        http://
        <el-input type="text" v-model="url"></el-input>
        .xuexibang.cn
      </template>
      <template v-else>
        {{ realURL }}
      </template>
      <div class="buttons" v-if="urlaction">
        <dsf-button @click="saveURL">保存</dsf-button>
      </div>
      <div class="buttons" v-else>
        <el-button type="text" @click="copyURL">复制</el-button>
        <el-button type="text" @click="openURL">打开</el-button>
        <el-button type="text" @click="changeURL">修改</el-button>
      </div>
    </div>
    <!-- <div class="subtitle margint30 marginb20">高级设置，设置企业域名</div>
    <div class="line">第一步：输入设置的域名</div>
    <div class="marginb30"><el-input type="text" class="ipconfig" v-model="ipconfig" placeholder="请输入域名"></el-input></div>
    <div class="line">第二步：设置域名</div>
    <div class="line">验证域名所有权</div>
    <div class="line margint20">登录您的域名管理页面，给您的域名增加CNAME解析记录值。</div>
    <div class="title t2">CNAME解析一般在2-24个小时后生效，如果您无法直接设置您的域名，请联系您的域名服务商</div>
    <div class="line margint20">设置域名DNS记录</div>
    <div class="subline">登录您的域名管理页面，给您的域名增加 DNS 解析记录值。</div>
    <div class="title t2">如果您之前曾经设置DNS记录，您需要删除原有的DNS记录以避免产生冲突。</div>
    <div class="line margint20">第三步：绑定验证</div>
    <dsf-button @click="checkBind">绑定验证</dsf-button> -->
  </div>
</template>
<script>
export default dsf.component({
  name: "DsfNcSaasSettingURL",
  ctrlCaption: "URL地址设置",
  mixins: [$mixins.control],
  props: {
    bindURL: {
      type: String,
      default: "",
    },
  },
  computed: {
    realURL() {
      return "http://" + this.url + ".xuexibang.cn";
    },
  },
  data() {
    return {
      url: "",
      urlaction: false,
      ipconfig: "",
      saasGroupId: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/getSaasGropu";
      let params = { unitId: this.queryString.unitId };
      dsf.http
        .get(sourceUrl, params, $$webRoot.nc)
        .done(({ success, data, message }) => {
          this.url = data.domainNameSimple;
          this.saasGroupId = data.saasGroupId;
        })
        .error(() => {
          dsf.layer.pc.message("获取数据异常:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    saveURL() {
      if (this.url.length == 0) {
        dsf.layer.message("请输入地址后操作！", false);
        return;
      }
      let reg = /(^[\w.-]+(?:(\.)+[\w:]+)$)|(^[\w-]+$)/;
      if (!reg.test(this.url)) {
        dsf.layer.message("请输入合法地址！", false);
        return;
      }
      const loading = dsf.layer.loading();
      let sourceUrl = "/nc/saas/saveSaasGropu";
      let params = { domainNameSimple: this.url, unitId: this.queryString.unitId };
      const header = {
        "Content-Type": "application/json",
      };
      dsf.http
        .post(sourceUrl, { ...params, saasGroupId: this.saasGroupId }, { headers: header }, $$webRoot.nc)
        .done(({ success, data, message }) => {
          if (success) {
            this.urlaction = false;
            dsf.layer.pc.message("保存成功！", success);
          } else {
            dsf.layer.pc.message("保存失败：" + message, success);
          }
        })
        .error(() => {
          dsf.layer.pc.message("保存失败:" + err, false);
        })
        .always(res => {
          dsf.layer.closeLoading(loading);
        });
    },
    copyURL() {
      function copy(text) {
        if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(text).then(() => {});
        } else {
          let textArea = document.createElement("textarea");
          textArea.value = text;
          //使textarea不在viewport内，同时设置不可见
          textArea.style.position = "absolute";
          textArea.style.opacity = 0;
          textArea.style.left = "-999999999px";
          textArea.style.top = "-999999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
          });
        }
      }
      copy(this.realURL).then(() => {
        dsf.layer.message("复制成功！", true);
      });
    },
    openURL() {
      var form = document.createElement("form");
      form.action = this.realURL;
      form.target = "_blank";
      form.method = "POST";
      document.body.appendChild(form);
      form.submit();
      form.remove();
    },
    changeURL() {
      this.urlaction = true;
    },
    checkBind() {
      dsf.layer.message("绑定验证成功！", true);
    },
  },
});
</script>
