var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf_course_player", style: { width: _vm.itemWidth } },
    [
      _c(
        "div",
        {
          staticClass: "course-player-inner",
          style: { height: _vm.docHeight },
        },
        [
          _c("div", { staticClass: "course-player-left" }, [
            _c("div", { staticClass: "course-header-left" }, [
              _c("div", { staticClass: "course-header-left-wrap" }, [
                _vm.baseInfo
                  ? _c(
                      "span",
                      {
                        staticClass: "course-header-left-title ellipsis",
                        attrs: { title: _vm.baseInfo.title || "" },
                      },
                      [_vm._v(_vm._s(_vm.baseInfo.title || ""))]
                    )
                  : _vm._e(),
                _vm.courseRate
                  ? _c(
                      "div",
                      { staticClass: "course-header-rate" },
                      [
                        _c("el-rate", {
                          attrs: {
                            max: _vm.maxRate,
                            disabled: "",
                            "show-score": "",
                            "text-color": "#ff9900",
                            "allow-half": "",
                          },
                          model: {
                            value: _vm.courseRate,
                            callback: function ($$v) {
                              _vm.courseRate = $$v
                            },
                            expression: "courseRate",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showBtnChange
                  ? _c("div", { staticClass: "course-header-left-buttons" }, [
                      _c(
                        "button",
                        {
                          class: {
                            "course-header-left-buttons-sel": _vm.playPattern,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePattern(true)
                            },
                          },
                        },
                        [_vm._v("视频模式")]
                      ),
                      _c(
                        "button",
                        {
                          class: {
                            "course-header-left-buttons-sel": !_vm.playPattern,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changePattern(false)
                            },
                          },
                        },
                        [_vm._v("讲义模式")]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { class: ["course-hear-or-see"] }, [
                  _c(
                    "button",
                    {
                      class: ["btn1", _vm.videoStatus ? "" : "transparent"],
                      on: { click: _vm.toggleStatus },
                    },
                    [
                      _c("i", {
                        class: [
                          "iconfont",
                          _vm.videoStatus
                            ? "icon-yanjing1"
                            : "icon-24gf-headphones",
                        ],
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.videoStatus ? "看课" : "听课")),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm.isView != 1
                ? _c("div", { staticClass: "course-header-left-event" }, [
                    _c(
                      "span",
                      {
                        class: { "active-item": _vm.isAssessed },
                        on: {
                          click: function ($event) {
                            return _vm.handleComment()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-pingjia1" }),
                        _vm._v(" 评价 "),
                      ]
                    ),
                    _vm.isShowLiked
                      ? _c(
                          "span",
                          {
                            class: { "active-item": _vm.hasLiked },
                            on: {
                              click: function ($event) {
                                return _vm.handleLiked()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-dianzan" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.hasLiked ? "已点赞" : "点赞") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        class: { "active-item": _vm.hasCollected },
                        on: {
                          click: function ($event) {
                            return _vm.handleCollect()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont icon-shoucang1" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.hasCollected ? "已收藏" : "收藏") +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        class: { "active-item": _vm.hasJoin },
                        on: { click: _vm.joinStudy },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-kecheng-jiarujihua",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.hasJoin ? "取消计划" : "加入计划") +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleBack()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-kecheng-fankui",
                        }),
                        _vm._v(" 反馈 "),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "course-player-pattern" }, [
              _vm.curPlayItem && _vm.showBtnChange
                ? _c(
                    "div",
                    {
                      ref: "dragItem",
                      staticClass: "course-player-drag",
                      class: { playPattern: !_vm.playPattern },
                    },
                    [
                      _vm.curPlayItem
                        ? _c("dsf-nc-ckplayer", {
                            ref: "coursePlayer",
                            attrs: {
                              videoSource: _vm.videoSrc,
                              cover: _vm.baseInfo && _vm.baseInfo.cover,
                              allowDrag: _vm.allowDrag,
                              is_allow_drag: true,
                              videoStatus: _vm.videoStatus,
                            },
                            on: {
                              playerHasPlay: function ($event) {
                                return _vm.playerHasPlay()
                              },
                              playerHasPause: function ($event) {
                                return _vm.playerHasPause()
                              },
                              playHasEnd: _vm.playEnd,
                              playerPlayingTime: _vm.playerPlayingTime,
                              playbackRateUpdate: _vm.playbackRateUpdate,
                              nextCourse: _vm.nextCourse,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showDocument
                ? _c("div", { staticClass: "course-document-pattern" }, [
                    _c("iframe", {
                      staticStyle: { background: "rgba(255, 255, 255, 1)" },
                      attrs: {
                        src: _vm.docUrl,
                        frameborder: "0",
                        scrolling: "no",
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { ref: "rightBox", staticClass: "course-player-right" }, [
            _c(
              "div",
              {
                staticClass: "course-toggle-bar-wrap",
                on: {
                  click: function ($event) {
                    return _vm.toggle()
                  },
                },
              },
              [_vm._m(0)]
            ),
            _c("div", { staticClass: "right-box-inner" }, [
              _c("div", { staticClass: "right-header-wrap" }, [
                !_vm.dsf.isEmptyObject(_vm.baseInfo) &&
                _vm.baseInfo.teacherList &&
                _vm.baseInfo.teacherList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "right-header" },
                      [
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              height: "140px",
                              arrow:
                                _vm.baseInfo.teacherList.length > 1
                                  ? "hover"
                                  : "never",
                              "indicator-position":
                                _vm.baseInfo.teacherList.length > 1
                                  ? ""
                                  : "none",
                              autoplay: false,
                            },
                          },
                          _vm._l(_vm.baseInfo.teacherList, function (item) {
                            return _c("el-carousel-item", { key: item.id }, [
                              _c(
                                "div",
                                {
                                  staticClass: "teacher",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toTeacherDetail(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avatar" },
                                    [
                                      _c("DsfNcDefaultHeader", {
                                        staticClass: "default-avatar",
                                        attrs: {
                                          src: item.photo,
                                          width: "90px",
                                          height: "120px",
                                          round: false,
                                          defaultHeaderUrl:
                                            _vm.dsf.config
                                              .setting_nc_image_teacher_header_img ||
                                            _vm.dsf.config
                                              .setting_public_teacher_default_header,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "desc" }, [
                                    _c("h3", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "dept ellipsis-2" },
                                      [_vm._v(_vm._s(item.unitJob))]
                                    ),
                                    _c("span", { staticClass: "total" }, [
                                      _vm._v(
                                        _vm._s(item.courseNum || 0) +
                                          "门精品课程"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ])
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "right-tab-items" }, [
                _c("div", { staticClass: "tab-menus-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "tab-menus" },
                    _vm._l(_vm.tabList, function (item, $index) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.show,
                              expression: "item.show",
                            },
                          ],
                          key: item.id,
                          class: [
                            "tab-menus-name",
                            _vm.getCurTabSelMenu($index),
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.tabMenuChange($index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    style: "height:" + (_vm.winH - 220) + "px",
                  },
                  [
                    _vm.tabIdx == 0
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-nc-course-chapter", {
                              attrs: { chapters: _vm.chapterInfo },
                              on: {
                                changeCurPlayCourse: _vm.changeCurPlayCourse,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tabIdx == 1
                      ? _c("div", { staticClass: "content-item" }, [
                          _vm.baseInfo && _vm.baseInfo.content
                            ? _c("div", {
                                staticClass: "introduce",
                                domProps: {
                                  innerHTML: _vm._s(_vm.baseInfo.content || ""),
                                },
                              })
                            : _vm._e(),
                          _vm.baseInfo &&
                          _vm.baseInfo.labels &&
                          _vm.baseInfo.labels.length
                            ? _c("div", { staticClass: "desc-laybes" }, [
                                _c("span", [_vm._v("标签:")]),
                                _c(
                                  "div",
                                  { staticClass: "tags" },
                                  _vm._l(_vm.baseInfo.labels, function (item) {
                                    return _c(
                                      "span",
                                      { key: item, staticClass: "tag" },
                                      [_vm._v(_vm._s(item))]
                                    )
                                  }),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          !_vm.baseInfo.content
                            ? _c("div", { staticClass: "dsf-nc-no-data" }, [
                                _c("i", { staticClass: "no-data-icon" }),
                                _c("p", [_vm._v("暂无简介")]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.tabIdx == 2 && !_vm.isView
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-nc-note-item", {
                              attrs: {
                                curItem: _vm.getCurPlayItem,
                                relationId: _vm.queryiId,
                                queryiType: _vm.queryiType,
                                item: _vm.baseInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tabIdx == 3 && _vm.isView
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-nc-advice", {
                              attrs: {
                                relationId: _vm.queryiId,
                                queryiType: _vm.queryiType,
                                item: _vm.baseInfo,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.tabIdx == 4 &&
                    _vm.dsf.config.setting_nc_player_course_comment == "1"
                      ? _c(
                          "div",
                          { staticClass: "content-item" },
                          [
                            _c("dsf-view-part", {
                              staticClass: "comment",
                              staticStyle: { height: "100%" },
                              attrs: {
                                path: "/pc/nc/page/pc/course/coursecomment",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.showComment
        ? _c(
            "div",
            { staticClass: "dialog-comment" },
            [
              _c("div", {
                staticClass: "comment-mask",
                on: {
                  click: function ($event) {
                    _vm.showComment = !_vm.showComment
                  },
                },
              }),
              _c("commentDialog", {
                attrs: {
                  id: _vm.queryiId,
                  queryiType: _vm.queryiType,
                  item: _vm.initItem,
                  isComment: _vm.initItem.isAssessed,
                },
                on: { commentSuccess: _vm.commentSuccess },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isFace
        ? _c("faceDialog", {
            attrs: { specialId: _vm.queryiId, isShow: _vm.isShow },
            on: { open: _vm.open, shure: _vm.shure },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toggle-bar" }, [_c("i", [_vm._v("||")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }